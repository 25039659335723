import moment from 'moment';
import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CsvDownloader from 'react-csv-downloader';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import ReactToPrint from 'react-to-print';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, Row } from 'reactstrap';
import { TableLoader } from '../../../components/Loaders';
import { accountAction } from '../../actions';
import { accountConstants } from '../../constants';
import { DepositReceipts } from './DepositReceipts';
import { handleValidation } from './Validation';
import ViewInvoice from './ViewInvoice';



class AccountList extends Component {
    constructor(props) {
        super(props)
        let days = new Date().getDate() - 1;
        this.state = {
            startDate: moment().subtract(days, 'days'),
            endDate: moment(),
            serachField: {
                dateRange: ''
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            errors: {},
            columns: '',
            csv_data: [],
            modalOpan: false,
            is_disable: true
        }
        //        this.getAccountData = this.getAccountData.bind(this)
        this.handleApply = this.handleApply.bind(this)
        this.handleEvent = this.handleEvent.bind(this)
    }

    componentDidMount() {
        this.handleReset();
    }

    handleReset() {
        const { dispatch } = this.props;
        let days = new Date().getDate() - 1;
        this.setState({
            startDate: moment().subtract(days, 'days'),
            endDate: moment(),
            serachField: {
                dateRange: ''
            },
            errors: {}
        });


        dispatch({ type: accountConstants.GET_ACCOUNT_DATA_FAILURE });
    }
    handleEvent(event, picker) {
        var start = picker.startDate.format("DD MMM Y");
        var end = picker.endDate.format("DD MMM Y");
        let label = start + ' to ' + end;
        if (start === end) {
            label = start;
        }
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            serachField: {
                dateRange: label
            },
            errors: {}
        })
    }
    handleApply(event, picker) {

        const { dispatch } = this.props;
        let { startDate, endDate, serachField } = this.state
        const result = handleValidation(serachField)
        this.setState({ errors: result.errors })
        if (result.formIsValid) {
            let dataToSend = {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            }
            dispatch(accountAction.getAccountData(dataToSend));
        }
    }


    componentWillReceiveProps(props) {
        if (props.data) {
            let { columns, csv_data, is_disable } = this.state
            csv_data = []
            is_disable = true
            columns = [{
                id: 'cell1',
                displayName: 'GSTIN/UIN of Recipient'
            }, {
                id: 'cell2',
                displayName: 'Invoice Number'
            }, {
                id: 'cell3',
                displayName: 'Invoice date'
            }, {
                id: 'cell4',
                displayName: 'Invoice Value'
            }, {
                id: 'cell5',
                displayName: 'Place Of Supply'
            }, {
                id: 'cell6',
                displayName: 'Reverse Charge'
            }, {
                id: 'cell7',
                displayName: 'Invoice Type'
            }, {
                id: 'cell8',
                displayName: 'Account Name'
            }, {
                id: 'cell9',
                displayName: 'E-Commerce GSTIN'
            }, {
                id: 'cell10',
                displayName: 'Rate'
            }, {
                id: 'cell11',
                displayName: 'Taxable Value'
            }, {
                id: 'cell12',
                displayName: 'Cess Amount'
            }, {

                id: 'cell13',
                displayName: 'IGST Amount'
            }, {
                id: 'cell14',
                displayName: 'CGST Amount'
            }, {
                id: 'cell15',
                displayName: 'UTGST Amount'
            }];

            if (props.data.length) {
                is_disable = false
                props.data.forEach((d) => {
                    csv_data.push(
                        {
                            'cell1': d.gst ? d.gst : '-',
                            'cell2': d.invoice_no,
                            'cell3': d.invoice_date,
                            'cell4': d.export_total_amount,
                            'cell5': d.address,
                            'cell6': 'No',
                            'cell7': d.description,
                            'cell8': d.account_name,
                            'cell9': '',
                            'cell10': 18,
                            'cell11': d.export_amount,
                            'cell12': '',
                            'cell13': d.igst.replace("₹ ", ""),
                            'cell14': d.cgst.replace("₹ ", ""),
                            'cell15': d.utgst.replace("₹ ", ""),
                        }
                    )
                })
            }
            this.setState({ columns, csv_data, is_disable })
        }
    }
    render() {
        const { loading, data, total_amount_data } = this.props
        const { serachField, errors, columns, csv_data, is_disable } = this.state

        let _date = moment().format('YYYYMMDD');
        let GST_FILENAME = "GST_" + _date;
        let _this = this;
        console.log(data, total_amount_data);
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader> <i className="fas fa-money-check-alt"></i>  <b>Income Statement</b></CardHeader>
                            <CardBody>

                                <Form autocomplete="off">
                                    <div className="form-group">

                                        <div >
                                            <Row>
                                                <Col sm="9">


                                                    <DateRangePicker
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        //onApply={this.handleApply}
                                                        onEvent={this.handleEvent}
                                                        ranges={this.state.ranges}
                                                    >
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="Select Date Range" value={serachField.dateRange} id="date_picker" style={{ 'width': '220px' }} />
                                                            <span className="input-group-btn">
                                                                <Button className="default date-range-toggle" onClick={(e) => e.preventDefault()}>
                                                                    <i className="fa fa-calendar" />
                                                                </Button>
                                                            </span>
                                                        </div>
                                                    </DateRangePicker>
                                                    <Button color="primary" onClick={(e) => this.handleApply()} style={{ 'margin-left': '10px' }}>Fetch</Button>
                                                    <Button color="secondary" onClick={(e) => this.handleReset()} style={{ 'margin-left': '10px' }}>Reset</Button>

                                                    <FormFeedback>
                                                        {errors[`dateRange`] && errors[`dateRange`]}
                                                    </FormFeedback>
                                                </Col>

                                                <Col sm={3}>
                                                    <ReactToPrint
                                                        className="pull-right mx-2"
                                                        trigger={() => {
                                                            return <Button color='primary' className='pull-right btn-primary'>Print</Button>

                                                        }}
                                                        content={() => this.componentRef}
                                                    />
                                                    <CsvDownloader columns={columns} datas={csv_data} filename={GST_FILENAME} >
                                                        <Button className="pull-right t-0 mx-2" color="info" disabled={is_disable} type="button">
                                                            Export &nbsp;<i className="fas fa-file-csv" style={{ fontSize: "18px" }}></i>
                                                        </Button>
                                                    </CsvDownloader>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                </Form>
                                {
                                    loading ? <TableLoader /> :
                                        (data ? <div ref={el => (this.componentRef = el)} className="table-responsive">



                                            <hr />
                                            {/* <DataTable keyField="id"
                                                data={data || []} columns={columns}
                                                filter={filterFactory()}
                                                noDataIndication={"No data found"} /> */}
                                            {/* style={{ fontSize: 20 }} */}
                                            <table className='my-2 h5'  >
                                                <tr className='mx-4'>
                                                    <td>
                                                        Excluding GST  :
                                                    </td>
                                                    <td>
                                                        <span className='bg-success  px-2 rounded  '>
                                                            {total_amount_data && total_amount_data.total_ex_gst}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className='mx-4' >
                                                    <td>
                                                        IGST :
                                                    </td>
                                                    <td>
                                                        <span className='bg-success  px-2 rounded  '>
                                                            {total_amount_data && total_amount_data.total_igst}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className='mx-4'>
                                                    <td>
                                                        Total Amount :
                                                    </td>
                                                    <td>
                                                        <span className='bg-success  px-2 rounded  '> {total_amount_data && total_amount_data.total_ic_gst}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className='mx-4'>
                                                    <td>
                                                        Total Company  :
                                                    </td>
                                                    <td>
                                                        <span className='bg-success  px-2 rounded  '> {
                                                            data.length}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>


                                            <div className="table-responsive-sm  animated fadeIn">
                                                <table className="table table-sm table-condensed table-bordered table-striped  table-outline">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>SR. NO.</th>
                                                            <th>Repute ID</th>
                                                            <th>Name</th>
                                                            <th>Invoice Date</th>
                                                            <th>Invoice Number</th>
                                                            <th>Description</th>
                                                            <th>Amount<br /><small> (Excluding GST) </small></th>
                                                            <th>IGST</th>
                                                            <th>CGST</th>
                                                            <th>UTGST</th>
                                                            <th>Without GST</th>
                                                            <th>Payment Gateway Charges </th>
                                                            <th>Amount<br /><small> (Including GST) </small></th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.length ?
                                                            data.map(function (element, index) {
                                                                return (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{element.formated_repute_id}</td>
                                                                        <td>{element.name}</td>
                                                                        <td>{element.invoice_date}</td>
                                                                        <td>{element.invoice_no}</td>
                                                                        <td>{element.description}</td>
                                                                        <td>{element.amount}</td>
                                                                        <td>{element.igst}</td>
                                                                        <td>{element.cgst}</td>
                                                                        <td>{element.utgst}</td>
                                                                        <td>{element.without_gst ? 'YES' : 'NO'}</td>
                                                                        <td>{element.platform_charge}</td>
                                                                        <td>{element.total_amount}</td>
                                                                        <td>
                                                                            {element.is_cheque ?
                                                                                <Tooltip
                                                                                    // className="btn m-0 p-0"
                                                                                    interactive
                                                                                    arrow={true}
                                                                                    html={<div className='text-left'>
                                                                                        <b>Account Holder Name:</b> {element.cheque_details.account_holder_name} <br />
                                                                                        <b>Bank Name:</b>  {element.cheque_details.bank_name}<br />
                                                                                        <b>Cheque Amount:</b>  {element.cheque_details.cheque_amount}<br />
                                                                                        <b>Cheque Number:</b>  {element.cheque_details.cheque_number}<br />
                                                                                        <b>Account Number:</b>  {element.cheque_details.account_number}
                                                                                    </div>}>
                                                                                    <i className='fa fa-info-circle text-info'></i>
                                                                                </Tooltip> : false}
                                                                            &nbsp;

                                                                            {element.invoice_pdf ?
                                                                                <ViewInvoice pdf_url={element.invoice_pdf} invoice_number={element.invoice_pdf} /> : false}
                                                                        </td>
                                                                    </tr>)
                                                            })

                                                            : <tr>
                                                                <th colSpan="9" className="text-center">No Data Found</th>
                                                            </tr>}
                                                    </tbody>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="6" className="text-right">Total Amount</th>
                                                            <th>{total_amount_data.total_ex_gst}</th>
                                                            <th>{total_amount_data.total_igst}</th>
                                                            <th>{total_amount_data.total_cgst}</th>
                                                            <th>{total_amount_data.total_utgst}</th>
                                                            <th>-</th>
                                                            <th>{total_amount_data.platform_charge}</th>
                                                            <th>{total_amount_data.total_ic_gst}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div> : "")
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <DepositReceipts />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loading, data, total_amount_data } = state.getAccountData
    return {
        loading,
        data,
        total_amount_data
    }
}

const connectedAccountList = connect(mapStateToProps)(AccountList)
export { connectedAccountList as AccountList };

